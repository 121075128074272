/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Codes
// ====================================================

export interface Codes_selectCodeList_data_childCodes {
	__typename: 'CodeEntity'
	id: number
	name: string
}

export interface Codes_selectCodeList_data {
	__typename: 'CodeEntity'
	id: number
	name: string
	childCodes: Codes_selectCodeList_data_childCodes[]
}

export interface Codes_selectCodeList {
	__typename: 'CodesOutput'
	data: Codes_selectCodeList_data[] | null
}

export interface Codes {
	selectCodeList: Codes_selectCodeList
}

export interface CodesVariables {
	ids: number[]
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateDiary
// ====================================================

export interface CreateDiary_insertDiary {
	__typename: 'DiaryEntity'
	id: number
}

export interface CreateDiary {
	insertDiary: CreateDiary_insertDiary
}

export interface CreateDiaryVariables {
	data: InsertDiaryInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Diary
// ====================================================

export interface Diary_selectDiary_site {
	__typename: 'SiteEntity'
	id: number
}

export interface Diary_selectDiary_diaryItems_code {
	__typename: 'CodeEntity'
	id: number
	name: string
}

export interface Diary_selectDiary_diaryItems {
	__typename: 'DiaryItemEntity'
	id: number
	code: Diary_selectDiary_diaryItems_code
	value: string
}

export interface Diary_selectDiary {
	__typename: 'DiaryEntity'
	id: number
	site: Diary_selectDiary_site
	diaryItems: Diary_selectDiary_diaryItems[]
}

export interface Diary_selectCodeList_data_childCodes {
	__typename: 'CodeEntity'
	id: number
	name: string
}

export interface Diary_selectCodeList_data {
	__typename: 'CodeEntity'
	id: number
	name: string
	childCodes: Diary_selectCodeList_data_childCodes[]
}

export interface Diary_selectCodeList {
	__typename: 'CodesOutput'
	data: Diary_selectCodeList_data[] | null
}

export interface Diary {
	selectDiary: Diary_selectDiary | null
	selectCodeList: Diary_selectCodeList
}

export interface DiaryVariables {
	data: SelectDiaryInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateFacility
// ====================================================

export interface CreateFacility_insertFacility {
	__typename: 'FacilityEntity'
	id: number
}

export interface CreateFacility {
	insertFacility: CreateFacility_insertFacility
}

export interface CreateFacilityVariables {
	data: InsertFacilityInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Facilities
// ====================================================

export interface Facilities_selectFacilityListByWeb_facilities_locationCode {
	__typename: 'CodeEntity'
	id: number
	name: string
}

export interface Facilities_selectFacilityListByWeb_facilities_titleCode {
	__typename: 'CodeEntity'
	id: number
	name: string
}

export interface Facilities_selectFacilityListByWeb_facilities {
	__typename: 'FacilityEntity'
	id: number
	dateOfReceipt: any
	regDt: any
	completedDate: any
	locationCode: Facilities_selectFacilityListByWeb_facilities_locationCode
	titleCode: Facilities_selectFacilityListByWeb_facilities_titleCode
	company: string | null
	detail: string | null
	cost: number
	amount: number
	memo: string | null
}

export interface Facilities_selectFacilityListByWeb_paging {
	__typename: 'CommonPagingOutputType'
	lastPageNo: number | null
	pageNo: number | null
	endNo: number | null
	skip: number | null
	startNo: number | null
	totalCount: number | null
}

export interface Facilities_selectFacilityListByWeb {
	__typename: 'FacilitiesOutput'
	facilities: Facilities_selectFacilityListByWeb_facilities[]
	paging: Facilities_selectFacilityListByWeb_paging | null
}

export interface Facilities {
	selectFacilityListByWeb: Facilities_selectFacilityListByWeb
}

export interface FacilitiesVariables {
	data: SelectFacilityListInputByWeb
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Facility
// ====================================================

export interface Facility_selectFacility_locationCode {
	__typename: 'CodeEntity'
	id: number
	name: string
}

export interface Facility_selectFacility_titleCode {
	__typename: 'CodeEntity'
	id: number
	name: string
}

export interface Facility_selectFacility_site {
	__typename: 'SiteEntity'
	id: number
	name: string
}

export interface Facility_selectFacility {
	__typename: 'FacilityEntity'
	id: number
	dateOfReceipt: any
	regDt: any
	completedDate: any
	locationCode: Facility_selectFacility_locationCode
	titleCode: Facility_selectFacility_titleCode
	site: Facility_selectFacility_site
	company: string | null
	detail: string | null
	cost: number
	amount: number
	memo: string | null
}

export interface Facility {
	selectFacility: Facility_selectFacility
}

export interface FacilityVariables {
	data: SelectFacilityInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateFacility
// ====================================================

export interface UpdateFacility_updateFacility {
	__typename: 'FacilityOutputBoolean'
	data: boolean | null
}

export interface UpdateFacility {
	updateFacility: UpdateFacility_updateFacility
}

export interface UpdateFacilityVariables {
	data: UpdateFacilityInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FacilityOperation
// ====================================================

export interface FacilityOperation_selectCountBySiteIdAndYear_sludges {
	__typename: 'FacilityCountOutput'
	date: string
	count: number
}

export interface FacilityOperation_selectCountBySiteIdAndYear_facilities {
	__typename: 'FacilityCountOutput'
	date: string
	count: number
}

export interface FacilityOperation_selectCountBySiteIdAndYear_diaries {
	__typename: 'FacilityCountOutput'
	date: string
	count: number
}

export interface FacilityOperation_selectCountBySiteIdAndYear_influentFlows {
	__typename: 'FacilityCountOutput'
	date: string
	count: number
}

export interface FacilityOperation_selectCountBySiteIdAndYear_dailyChecks {
	__typename: 'FacilityCountOutput'
	date: string
	count: number
}

export interface FacilityOperation_selectCountBySiteIdAndYear_projects {
	__typename: 'FacilityCountOutput'
	date: string
	count: number
}

export interface FacilityOperation_selectCountBySiteIdAndYear {
	__typename: 'FacilityTotalCountOutput'
	sludges: FacilityOperation_selectCountBySiteIdAndYear_sludges[]
	facilities: FacilityOperation_selectCountBySiteIdAndYear_facilities[]
	diaries: FacilityOperation_selectCountBySiteIdAndYear_diaries[]
	influentFlows: FacilityOperation_selectCountBySiteIdAndYear_influentFlows[]
	dailyChecks: FacilityOperation_selectCountBySiteIdAndYear_dailyChecks[]
	projects: FacilityOperation_selectCountBySiteIdAndYear_projects[]
}

export interface FacilityOperation {
	selectCountBySiteIdAndYear: FacilityOperation_selectCountBySiteIdAndYear
}

export interface FacilityOperationVariables {
	data: GetCountInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: fileUpload
// ====================================================

export interface fileUpload_fileUpload {
	__typename: 'FileUploadRequest'
	refId: number
	url: string
}

export interface fileUpload {
	fileUpload: fileUpload_fileUpload | null
}

export interface fileUploadVariables {
	file: any
	refId: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FlowMeasure
// ====================================================

export interface FlowMeasure_selectFlowList {
	__typename: 'FlowEntity'
	id: number
	name: string
	ord: number
}

export interface FlowMeasure_selectInfluentFlowRecently_influentFlowItems_flow {
	__typename: 'FlowEntity'
	id: number
}

export interface FlowMeasure_selectInfluentFlowRecently_influentFlowItems {
	__typename: 'InfluentFlowItemEntity'
	result: number
	flow: FlowMeasure_selectInfluentFlowRecently_influentFlowItems_flow
}

export interface FlowMeasure_selectInfluentFlowRecently {
	__typename: 'InfluentFlowEntity'
	id: number
	regDt: any
	influentFlowItems: FlowMeasure_selectInfluentFlowRecently_influentFlowItems[]
}

export interface FlowMeasure_selectInfluentFlow_site {
	__typename: 'SiteEntity'
	id: number
	name: string
}

export interface FlowMeasure_selectInfluentFlow_influentFlowItems_flow {
	__typename: 'FlowEntity'
	id: number
}

export interface FlowMeasure_selectInfluentFlow_influentFlowItems {
	__typename: 'InfluentFlowItemEntity'
	result: number
	flow: FlowMeasure_selectInfluentFlow_influentFlowItems_flow
}

export interface FlowMeasure_selectInfluentFlow {
	__typename: 'InfluentFlowEntity'
	id: number
	regDt: any
	inspector: string
	site: FlowMeasure_selectInfluentFlow_site | null
	influentFlowItems: FlowMeasure_selectInfluentFlow_influentFlowItems[]
}

export interface FlowMeasure_selectTotalToRegDt_month {
	__typename: 'TotalMonthAndYearToRegDtOutput'
	id: number
	result: number
}

export interface FlowMeasure_selectTotalToRegDt_year {
	__typename: 'TotalMonthAndYearToRegDtOutput'
	id: number
	result: number
}

export interface FlowMeasure_selectTotalToRegDt {
	__typename: 'TotalToRegDtOutput'
	month: FlowMeasure_selectTotalToRegDt_month[]
	year: FlowMeasure_selectTotalToRegDt_year[]
}

export interface FlowMeasure {
	selectFlowList: FlowMeasure_selectFlowList[]
	selectInfluentFlowRecently: FlowMeasure_selectInfluentFlowRecently | null
	selectInfluentFlow: FlowMeasure_selectInfluentFlow | null
	selectTotalToRegDt: FlowMeasure_selectTotalToRegDt | null
}

export interface FlowMeasureVariables {
	siteId: number
	regDt: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Flows
// ====================================================

export interface Flows_selectInfluentFlowList_influentFlowItems_flow {
	__typename: 'FlowEntity'
	id: number
	name: string
}

export interface Flows_selectInfluentFlowList_influentFlowItems {
	__typename: 'InfluentFlowItemEntity'
	flow: Flows_selectInfluentFlowList_influentFlowItems_flow
	result: number
}

export interface Flows_selectInfluentFlowList {
	__typename: 'InfluentFlowEntity'
	id: number
	regDt: any
	influentFlowItems: Flows_selectInfluentFlowList_influentFlowItems[]
}

export interface Flows_selectFlowList {
	__typename: 'FlowEntity'
	id: number
	name: string
	ord: number
}

export interface Flows {
	selectInfluentFlowList: Flows_selectInfluentFlowList[]
	selectFlowList: Flows_selectFlowList[]
}

export interface FlowsVariables {
	data: SelectInfluentFlowListInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FlowsCount
// ====================================================

export interface FlowsCount_selectFlowMonthResultWithRegDt {
	__typename: 'MonthResultCountOutput'
	count: number
	name: string
	place: string
	regDt: string
}

export interface FlowsCount {
	selectFlowMonthResultWithRegDt: FlowsCount_selectFlowMonthResultWithRegDt[] | null
}

export interface FlowsCountVariables {
	data: SelectManagementInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateFlow
// ====================================================

export interface CreateFlow_insertInfluentFlow {
	__typename: 'InfluentFlowEntity'
	id: number
}

export interface CreateFlow {
	insertInfluentFlow: CreateFlow_insertInfluentFlow
}

export interface CreateFlowVariables {
	data: InsertInfluentFlowInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateGroup
// ====================================================

export interface CreateGroup_insertGroup {
	__typename: 'GroupEntity'
	id: number
}

export interface CreateGroup {
	insertGroup: CreateGroup_insertGroup
}

export interface CreateGroupVariables {
	data: InsertGroupInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Groups
// ====================================================

export interface Groups_selectGroupList_groups_sites {
	__typename: 'SiteEntity'
	id: number
	name: string
	address: string | null
}

export interface Groups_selectGroupList_groups {
	__typename: 'GroupEntity'
	id: number
	groupName: string
	manager: string | null
	cellPhone: string | null
	officePhone: string | null
	memo: string | null
	address: string | null
	regDt: any
	type: GroupTypeEnum
	sites: Groups_selectGroupList_groups_sites[]
}

export interface Groups_selectGroupList {
	__typename: 'SelectGroupListOutput'
	groups: Groups_selectGroupList_groups[]
}

export interface Groups {
	selectGroupList: Groups_selectGroupList
}

export interface GroupsVariables {
	data: SelectGroupsInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupsArea
// ====================================================

export interface GroupsArea_selectGroupList_groups_sites {
	__typename: 'SiteEntity'
	id: number
	name: string
	address: string | null
}

export interface GroupsArea_selectGroupList_groups {
	__typename: 'GroupEntity'
	id: number
	groupName: string
	manager: string | null
	cellPhone: string | null
	officePhone: string | null
	memo: string | null
	address: string | null
	regDt: any
	type: GroupTypeEnum
	sites: GroupsArea_selectGroupList_groups_sites[]
}

export interface GroupsArea_selectGroupList {
	__typename: 'SelectGroupListOutput'
	groups: GroupsArea_selectGroupList_groups[]
}

export interface GroupsArea_selectCodeList_data_childCodes {
	__typename: 'CodeEntity'
	id: number
	name: string
}

export interface GroupsArea_selectCodeList_data {
	__typename: 'CodeEntity'
	id: number
	name: string
	childCodes: GroupsArea_selectCodeList_data_childCodes[]
}

export interface GroupsArea_selectCodeList {
	__typename: 'CodesOutput'
	data: GroupsArea_selectCodeList_data[] | null
}

export interface GroupsArea {
	selectGroupList: GroupsArea_selectGroupList
	selectCodeList: GroupsArea_selectCodeList
}

export interface GroupsAreaVariables {
	data: SelectGroupsInput
	ids: number[]
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupsItem
// ====================================================

export interface GroupsItem_selectGroupList_groups_sites {
	__typename: 'SiteEntity'
	id: number
	name: string
}

export interface GroupsItem_selectGroupList_groups {
	__typename: 'GroupEntity'
	id: number
	groupName: string
	manager: string | null
	cellPhone: string | null
	officePhone: string | null
	memo: string | null
	address: string | null
	regDt: any
	type: GroupTypeEnum
	sites: GroupsItem_selectGroupList_groups_sites[]
}

export interface GroupsItem_selectGroupList {
	__typename: 'SelectGroupListOutput'
	groups: GroupsItem_selectGroupList_groups[]
}

export interface GroupsItem_selectItemList {
	__typename: 'ItemEntity'
	id: number
	name: string
}

export interface GroupsItem {
	selectGroupList: GroupsItem_selectGroupList
	selectItemList: GroupsItem_selectItemList[]
}

export interface GroupsItemVariables {
	data: SelectGroupsInput
	type?: ItemTypeEnum[] | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupsPaging
// ====================================================

export interface GroupsPaging_selectGroupList_groups_sites {
	__typename: 'SiteEntity'
	id: number
	name: string
}

export interface GroupsPaging_selectGroupList_groups {
	__typename: 'GroupEntity'
	id: number
	groupName: string
	manager: string | null
	cellPhone: string | null
	officePhone: string | null
	memo: string | null
	address: string | null
	regDt: any
	type: GroupTypeEnum
	sites: GroupsPaging_selectGroupList_groups_sites[]
}

export interface GroupsPaging_selectGroupList_paging {
	__typename: 'CommonPagingOutputType'
	startNo: number | null
	endNo: number | null
	lastPageNo: number | null
	pageNo: number | null
}

export interface GroupsPaging_selectGroupList {
	__typename: 'SelectGroupListOutput'
	groups: GroupsPaging_selectGroupList_groups[]
	paging: GroupsPaging_selectGroupList_paging | null
}

export interface GroupsPaging {
	selectGroupList: GroupsPaging_selectGroupList
}

export interface GroupsPagingVariables {
	data: SelectGroupsInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Group
// ====================================================

export interface Group_selectGroup_itemPrices_item {
	__typename: 'ItemEntity'
	id: number
	name: string
}

export interface Group_selectGroup_itemPrices {
	__typename: 'ItemPriceEntity'
	id: number
	item: Group_selectGroup_itemPrices_item
	price: number
}

export interface Group_selectGroup {
	__typename: 'GroupEntity'
	id: number
	groupName: string
	manager: string | null
	cellPhone: string | null
	officePhone: string | null
	memo: string | null
	address: string | null
	regDt: any
	contractFrom: any
	contractTo: any
	type: GroupTypeEnum
	itemPrices: Group_selectGroup_itemPrices[]
}

export interface Group_selectItemList {
	__typename: 'ItemEntity'
	id: number
	name: string
	measure: string
	type: ItemTypeEnum
}

export interface Group {
	selectGroup: Group_selectGroup | null
	selectItemList: Group_selectItemList[]
}

export interface GroupVariables {
	groupId: number
	itemTypes?: ItemTypeEnum[] | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveGroup
// ====================================================

export interface RemoveGroup {
	deleteGroup: boolean
}

export interface RemoveGroupVariables {
	id: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGroup
// ====================================================

export interface UpdateGroup_updateGroup {
	__typename: 'GroupOutputBoolean'
	data: boolean | null
}

export interface UpdateGroup {
	updateGroup: UpdateGroup_updateGroup
}

export interface UpdateGroupVariables {
	data: UpdateGroupInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Items
// ====================================================

export interface Items_selectItemList {
	__typename: 'ItemEntity'
	id: number
	name: string
	measure: string
	type: ItemTypeEnum
	ord: number
}

export interface Items {
	selectItemList: Items_selectItemList[]
}

export interface ItemsVariables {
	data: SelectItemListInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MedicineRemains
// ====================================================

export interface MedicineRemains_selectChemicalHistoryRemains_item {
	__typename: 'ItemEntity'
	id: number
	name: string
}

export interface MedicineRemains_selectChemicalHistoryRemains {
	__typename: 'ChemicalHistoryEntity'
	item: MedicineRemains_selectChemicalHistoryRemains_item
	remains: number
}

export interface MedicineRemains {
	selectChemicalHistoryRemains: MedicineRemains_selectChemicalHistoryRemains[] | null
}

export interface MedicineRemainsVariables {
	data: SelectChemicalHistoryListInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateMedicine
// ====================================================

export interface CreateMedicine_insertChemicalHistory {
	__typename: 'ChemicalHistoryEntity'
	id: number
}

export interface CreateMedicine {
	insertChemicalHistory: CreateMedicine_insertChemicalHistory[]
}

export interface CreateMedicineVariables {
	data: InsertChemicalHistoryInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Medicine
// ====================================================

export interface Medicine_chemicalForDay_site {
	__typename: 'SiteEntity'
	id: number
	name: string
}

export interface Medicine_chemicalForDay_item {
	__typename: 'ItemEntity'
	id: number
	measure: string
	name: string
}

export interface Medicine_chemicalForDay {
	__typename: 'ChemicalHistoryEntity'
	id: number
	regDt: any
	orderAmount: number
	useAmount: number
	site: Medicine_chemicalForDay_site | null
	item: Medicine_chemicalForDay_item
}

export interface Medicine {
	chemicalForDay: Medicine_chemicalForDay[]
}

export interface MedicineVariables {
	data: SelectChemicalHistoryListInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MedicineMonth
// ====================================================

export interface MedicineMonth_selectChemicalHistoryManagement_site {
	__typename: 'SiteEntity'
	id: number
	name: string
}

export interface MedicineMonth_selectChemicalHistoryManagement_item {
	__typename: 'ItemEntity'
	id: number
	measure: string
	name: string
}

export interface MedicineMonth_selectChemicalHistoryManagement {
	__typename: 'ChemicalHistoryEntity'
	id: number
	regDt: any
	orderAmount: number
	useAmount: number
	site: MedicineMonth_selectChemicalHistoryManagement_site | null
	item: MedicineMonth_selectChemicalHistoryManagement_item
}

export interface MedicineMonth {
	selectChemicalHistoryManagement: MedicineMonth_selectChemicalHistoryManagement[]
}

export interface MedicineMonthVariables {
	data: SelectManagementInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Notices
// ====================================================

export interface Notices_selectNoticeList_notices {
	__typename: 'NoticeEntity'
	id: number
	title: string
	regDt: any
}

export interface Notices_selectNoticeList_paging {
	__typename: 'CommonPagingOutputType'
	pageNo: number | null
	startNo: number | null
	endNo: number | null
	lastPageNo: number | null
}

export interface Notices_selectNoticeList {
	__typename: 'NoticeListOutput'
	notices: Notices_selectNoticeList_notices[]
	paging: Notices_selectNoticeList_paging | null
}

export interface Notices {
	selectNoticeList: Notices_selectNoticeList
}

export interface NoticesVariables {
	data: SelectNoticeListInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateNotice
// ====================================================

export interface CreateNotice_insertNotice {
	__typename: 'NoticeEntity'
	id: number
}

export interface CreateNotice {
	insertNotice: CreateNotice_insertNotice
}

export interface CreateNoticeVariables {
	data: InsertNoticeInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateNotice
// ====================================================

export interface UpdateNotice_updateNotice {
	__typename: 'OutputByBoolean'
	data: boolean | null
}

export interface UpdateNotice {
	updateNotice: UpdateNotice_updateNotice
}

export interface UpdateNoticeVariables {
	data: UpdateNoticeInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteNotice
// ====================================================

export interface DeleteNotice_deleteNotice {
	__typename: 'OutputByBoolean'
	data: boolean | null
}

export interface DeleteNotice {
	deleteNotice: DeleteNotice_deleteNotice
}

export interface DeleteNoticeVariables {
	data: UpdateNoticeInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Notice
// ====================================================

export interface Notice_selectNotice {
	__typename: 'NoticeEntity'
	id: number
	title: string
	regDt: any
	content: string
}

export interface Notice {
	selectNotice: Notice_selectNotice
}

export interface NoticeVariables {
	id: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOperation
// ====================================================

export interface CreateOperation_insertDailyCheck {
	__typename: 'DailyCheckEntity'
	id: number
}

export interface CreateOperation {
	insertDailyCheck: CreateOperation_insertDailyCheck
}

export interface CreateOperationVariables {
	data: InsertDailyCheckInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Operation
// ====================================================

export interface Operation_dailyCheckByDay_site {
	__typename: 'SiteEntity'
	id: number
}

export interface Operation_dailyCheckByDay_dailyCheckItems_code_parentCode {
	__typename: 'CodeEntity'
	id: number
	name: string
}

export interface Operation_dailyCheckByDay_dailyCheckItems_code {
	__typename: 'CodeEntity'
	id: number
	name: string
	parentCode: Operation_dailyCheckByDay_dailyCheckItems_code_parentCode | null
}

export interface Operation_dailyCheckByDay_dailyCheckItems {
	__typename: 'DailyCheckItemEntity'
	id: number
	value: DailyCheckItemEnum
	memo: string | null
	code: Operation_dailyCheckByDay_dailyCheckItems_code
}

export interface Operation_dailyCheckByDay {
	__typename: 'DailyCheckEntity'
	id: number
	site: Operation_dailyCheckByDay_site | null
	dailyCheckItems: Operation_dailyCheckByDay_dailyCheckItems[] | null
}

export interface Operation {
	dailyCheckByDay: Operation_dailyCheckByDay | null
}

export interface OperationVariables {
	data: DailyCheckByDayInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DailyOperation
// ====================================================

export interface DailyOperation_selectDiary_diaryItems_code {
	__typename: 'CodeEntity'
	id: number
	name: string
}

export interface DailyOperation_selectDiary_diaryItems {
	__typename: 'DiaryItemEntity'
	id: number
	value: string
	code: DailyOperation_selectDiary_diaryItems_code
}

export interface DailyOperation_selectDiary {
	__typename: 'DiaryEntity'
	id: number
	diaryItems: DailyOperation_selectDiary_diaryItems[]
}

export interface DailyOperation_chemicalForDay_site {
	__typename: 'SiteEntity'
	id: number
	name: string
}

export interface DailyOperation_chemicalForDay_item {
	__typename: 'ItemEntity'
	id: number
	measure: string
	name: string
}

export interface DailyOperation_chemicalForDay {
	__typename: 'ChemicalHistoryEntity'
	id: number
	regDt: any
	orderAmount: number
	useAmount: number
	site: DailyOperation_chemicalForDay_site | null
	item: DailyOperation_chemicalForDay_item
}

export interface DailyOperation_selectFlowList {
	__typename: 'FlowEntity'
	id: number
	name: string
	ord: number
}

export interface DailyOperation_selectProjectListByRegDt_measurements_item {
	__typename: 'ItemEntity'
	id: number
	name: string
}

export interface DailyOperation_selectProjectListByRegDt_measurements_sample {
	__typename: 'SampleEntity'
	id: number
	name: string
}

export interface DailyOperation_selectProjectListByRegDt_measurements {
	__typename: 'MeasurementEntity'
	id: number
	item: DailyOperation_selectProjectListByRegDt_measurements_item
	sample: DailyOperation_selectProjectListByRegDt_measurements_sample
	ppm: number
}

export interface DailyOperation_selectProjectListByRegDt_user {
	__typename: 'MUserEntity'
	id: number
	name: string
}

export interface DailyOperation_selectProjectListByRegDt {
	__typename: 'ProjectEntity'
	id: number
	regDt: any
	measurements: DailyOperation_selectProjectListByRegDt_measurements[]
	user: DailyOperation_selectProjectListByRegDt_user
}

export interface DailyOperation_selectInfluentFlowRecently_influentFlowItems_flow {
	__typename: 'FlowEntity'
	id: number
}

export interface DailyOperation_selectInfluentFlowRecently_influentFlowItems {
	__typename: 'InfluentFlowItemEntity'
	result: number
	flow: DailyOperation_selectInfluentFlowRecently_influentFlowItems_flow
}

export interface DailyOperation_selectInfluentFlowRecently {
	__typename: 'InfluentFlowEntity'
	id: number
	regDt: any
	influentFlowItems: DailyOperation_selectInfluentFlowRecently_influentFlowItems[]
}

export interface DailyOperation_selectInfluentFlow_site {
	__typename: 'SiteEntity'
	id: number
	name: string
}

export interface DailyOperation_selectInfluentFlow_influentFlowItems_flow {
	__typename: 'FlowEntity'
	id: number
}

export interface DailyOperation_selectInfluentFlow_influentFlowItems {
	__typename: 'InfluentFlowItemEntity'
	result: number
	flow: DailyOperation_selectInfluentFlow_influentFlowItems_flow
}

export interface DailyOperation_selectInfluentFlow {
	__typename: 'InfluentFlowEntity'
	id: number
	regDt: any
	inspector: string
	site: DailyOperation_selectInfluentFlow_site | null
	influentFlowItems: DailyOperation_selectInfluentFlow_influentFlowItems[]
}

export interface DailyOperation_selectTotalToRegDt_month {
	__typename: 'TotalMonthAndYearToRegDtOutput'
	id: number
	result: number
}

export interface DailyOperation_selectTotalToRegDt_year {
	__typename: 'TotalMonthAndYearToRegDtOutput'
	id: number
	result: number
}

export interface DailyOperation_selectTotalToRegDt {
	__typename: 'TotalToRegDtOutput'
	month: DailyOperation_selectTotalToRegDt_month[]
	year: DailyOperation_selectTotalToRegDt_year[]
}

export interface DailyOperation_selectChemicalHistoryRemains_item {
	__typename: 'ItemEntity'
	id: number
	name: string
}

export interface DailyOperation_selectChemicalHistoryRemains {
	__typename: 'ChemicalHistoryEntity'
	item: DailyOperation_selectChemicalHistoryRemains_item
	remains: number
}

export interface DailyOperation {
	selectDiary: DailyOperation_selectDiary | null
	chemicalForDay: DailyOperation_chemicalForDay[]
	selectFlowList: DailyOperation_selectFlowList[]
	selectProjectListByRegDt: DailyOperation_selectProjectListByRegDt[]
	selectInfluentFlowRecently: DailyOperation_selectInfluentFlowRecently | null
	selectInfluentFlow: DailyOperation_selectInfluentFlow | null
	selectTotalToRegDt: DailyOperation_selectTotalToRegDt | null
	selectChemicalHistoryRemains: DailyOperation_selectChemicalHistoryRemains[] | null
}

export interface DailyOperationVariables {
	regDt: string
	siteId: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Samples
// ====================================================

export interface Samples_selectSampleList {
	__typename: 'SampleEntity'
	id: number
	name: string
	analysisProcess: AnalysisProcessEnum
}

export interface Samples {
	selectSampleList: Samples_selectSampleList[]
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Sites
// ====================================================

export interface Sites_selectSiteList_sites_group_itemPrices_item {
	__typename: 'ItemEntity'
	id: number
}

export interface Sites_selectSiteList_sites_group_itemPrices {
	__typename: 'ItemPriceEntity'
	item: Sites_selectSiteList_sites_group_itemPrices_item
	price: number
}

export interface Sites_selectSiteList_sites_group {
	__typename: 'GroupEntity'
	id: number
	groupName: string
	itemPrices: Sites_selectSiteList_sites_group_itemPrices[]
}

export interface Sites_selectSiteList_sites_area {
	__typename: 'CodeEntity'
	name: string
	id: number
}

export interface Sites_selectSiteList_sites {
	__typename: 'SiteEntity'
	id: number
	address: string | null
	group: Sites_selectSiteList_sites_group | null
	name: string
	cellPhone: string | null
	manager: string | null
	regDt: any
	memo: string | null
	area: Sites_selectSiteList_sites_area | null
}

export interface Sites_selectSiteList_paging {
	__typename: 'CommonPagingOutputType'
	startNo: number | null
	limit: number | null
	endNo: number | null
	lastPageNo: number | null
	pageNo: number | null
}

export interface Sites_selectSiteList {
	__typename: 'SelectSiteListOutput'
	sites: Sites_selectSiteList_sites[]
	paging: Sites_selectSiteList_paging | null
}

export interface Sites {
	selectSiteList: Sites_selectSiteList
}

export interface SitesVariables {
	data: SelectSitesInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Site
// ====================================================

export interface Site_selectSite_group {
	__typename: 'GroupEntity'
	id: number
	groupName: string
}

export interface Site_selectSite_area {
	__typename: 'CodeEntity'
	id: number
	name: string
}

export interface Site_selectSite {
	__typename: 'SiteEntity'
	id: number
	address: string | null
	group: Site_selectSite_group | null
	name: string
	manager: string | null
	cellPhone: string | null
	memo: string | null
	salesType: SiteSalesTypeEnum | null
	area: Site_selectSite_area | null
}

export interface Site {
	selectSite: Site_selectSite | null
}

export interface SiteVariables {
	data: SelectSiteInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateSite
// ====================================================

export interface CreateSite_insertSite {
	__typename: 'SiteEntity'
	id: number
}

export interface CreateSite {
	insertSite: CreateSite_insertSite
}

export interface CreateSiteVariables {
	data: InsertSiteInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveSite
// ====================================================

export interface RemoveSite {
	deleteSite: boolean
}

export interface RemoveSiteVariables {
	id: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSite
// ====================================================

export interface UpdateSite {
	updateSite: boolean
}

export interface UpdateSiteVariables {
	data: UpdateSiteInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateSludge
// ====================================================

export interface CreateSludge_insertSludge {
	__typename: 'SludgeEntity'
	id: number
}

export interface CreateSludge {
	insertSludge: CreateSludge_insertSludge
}

export interface CreateSludgeVariables {
	data: InsertSludgeInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Sludges
// ====================================================

export interface Sludges_selectSludgeList_site {
	__typename: 'SiteEntity'
	id: number
	name: string
}

export interface Sludges_selectSludgeList {
	__typename: 'SludgeEntity'
	id: number
	transferCompany: string
	value: number
	regDt: any
	transferProcessingFacility: string
	generatingFacility: string
	site: Sludges_selectSludgeList_site
}

export interface Sludges {
	selectSludgeList: Sludges_selectSludgeList[]
}

export interface SludgesVariables {
	data: SelectSludgeListInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SludgeDate
// ====================================================

export interface SludgeDate_selectSludgeByDate_site {
	__typename: 'SiteEntity'
	id: number
	name: string
}

export interface SludgeDate_selectSludgeByDate {
	__typename: 'SludgeEntity'
	id: number
	transferCompany: string
	value: number
	regDt: any
	transferProcessingFacility: string
	generatingFacility: string
	site: SludgeDate_selectSludgeByDate_site
}

export interface SludgeDate {
	selectSludgeByDate: SludgeDate_selectSludgeByDate | null
}

export interface SludgeDateVariables {
	data: SelectSludgeListInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSludge
// ====================================================

export interface UpdateSludge_updateSludge {
	__typename: 'SludgeOutputBoolean'
	data: boolean | null
}

export interface UpdateSludge {
	updateSludge: UpdateSludge_updateSludge
}

export interface UpdateSludgeVariables {
	data: UpdateSludgeInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SludgeYear
// ====================================================

export interface SludgeYear_selectSludgeByYear_site {
	__typename: 'SiteEntity'
	id: number
	name: string
}

export interface SludgeYear_selectSludgeByYear {
	__typename: 'SludgeEntity'
	id: number
	transferCompany: string
	value: number
	regDt: any
	transferProcessingFacility: string
	generatingFacility: string
	site: SludgeYear_selectSludgeByYear_site
}

export interface SludgeYear {
	selectSludgeByYear: SludgeYear_selectSludgeByYear[] | null
}

export interface SludgeYearVariables {
	data: SelectSludgeListInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Login
// ====================================================

export interface Login_signIn {
	__typename: 'MUserEntity'
	id: number
}

export interface Login {
	signIn: Login_signIn
}

export interface LoginVariables {
	userId: string
	password: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Logout
// ====================================================

export interface Logout {
	logout: boolean
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignUp
// ====================================================

export interface SignUp_signUp {
	__typename: 'MUserEntity'
	id: number
}

export interface SignUp {
	signUp: SignUp_signUp
}

export interface SignUpVariables {
	data: SignUpInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: User
// ====================================================

export interface User_selectUser_role {
	__typename: 'CodeEntity'
	id: number
	name: string
}

export interface User_selectUser_sites_group {
	__typename: 'GroupEntity'
	id: number
	groupName: string
}

export interface User_selectUser_sites {
	__typename: 'SiteEntity'
	id: number
	name: string
	group: User_selectUser_sites_group | null
}

export interface User_selectUser {
	__typename: 'MUserEntity'
	id: number
	userId: string
	password: string
	email: string | null
	cellPhone: string | null
	name: string
	memo: string | null
	role: User_selectUser_role
	sites: User_selectUser_sites[] | null
}

export interface User {
	selectUser: User_selectUser
}

export interface UserVariables {
	data: SelectUserInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Users
// ====================================================

export interface Users_selectUserList_users_role {
	__typename: 'CodeEntity'
	name: string
}

export interface Users_selectUserList_users_sites_group {
	__typename: 'GroupEntity'
	id: number
	groupName: string
}

export interface Users_selectUserList_users_sites {
	__typename: 'SiteEntity'
	id: number
	group: Users_selectUserList_users_sites_group | null
}

export interface Users_selectUserList_users {
	__typename: 'MUserEntity'
	id: number
	userId: string
	cellPhone: string | null
	name: string | null
	email: string | null
	regDt: any
	role: Users_selectUserList_users_role
	sites: Users_selectUserList_users_sites[] | null
}

export interface Users_selectUserList_paging {
	__typename: 'CommonPagingOutputType'
	startNo: number | null
	lastPageNo: number | null
	endNo: number | null
	limit: number | null
	pageNo: number | null
}

export interface Users_selectUserList {
	__typename: 'SelectUserListResponse'
	users: Users_selectUserList_users[]
	paging: Users_selectUserList_paging | null
}

export interface Users {
	selectUserList: Users_selectUserList
}

export interface UsersVariables {
	data: SelectUserListInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Me
// ====================================================

export interface Me_me_sites {
	__typename: 'SiteEntity'
	address: string | null
	name: string
	id: number
}

export interface Me_me_role {
	__typename: 'CodeEntity'
	id: number
	name: string
}

export interface Me_me {
	__typename: 'MUserEntity'
	id: number
	userId: string
	cellPhone: string | null
	email: string | null
	name: string
	sites: Me_me_sites[] | null
	role: Me_me_role
}

export interface Me {
	me: Me_me
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUser
// ====================================================

export interface UpdateUser_updateUser {
	__typename: 'UserOutputBoolean'
	data: boolean | null
}

export interface UpdateUser {
	updateUser: UpdateUser_updateUser
}

export interface UpdateUserVariables {
	data: UpdateUserInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: WaterCount
// ====================================================

export interface WaterCount_selectProjectCountBySiteIdAndMonth_site {
	__typename: 'SiteEntity'
	name: string
	id: number
}

export interface WaterCount_selectProjectCountBySiteIdAndMonth_user {
	__typename: 'MUserEntity'
	id: number
	name: string
}

export interface WaterCount_selectProjectCountBySiteIdAndMonth {
	__typename: 'ProjectCountOutput'
	count: number
	site: WaterCount_selectProjectCountBySiteIdAndMonth_site
	user: WaterCount_selectProjectCountBySiteIdAndMonth_user
}

export interface WaterCount {
	selectProjectCountBySiteIdAndMonth: WaterCount_selectProjectCountBySiteIdAndMonth[]
}

export interface WaterCountVariables {
	data: SelectManagementInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: WaterMonth
// ====================================================

export interface WaterMonth_selectProjectByMonth_measurements_item {
	__typename: 'ItemEntity'
	id: number
	name: string
}

export interface WaterMonth_selectProjectByMonth_measurements_sample {
	__typename: 'SampleEntity'
	id: number
	name: string
}

export interface WaterMonth_selectProjectByMonth_measurements {
	__typename: 'MeasurementEntity'
	item: WaterMonth_selectProjectByMonth_measurements_item
	sample: WaterMonth_selectProjectByMonth_measurements_sample
	ppm: number
	itemKind: string
	dilution: number
}

export interface WaterMonth_selectProjectByMonth {
	__typename: 'ProjectEntity'
	id: number
	regDt: any
	analysisProcess: AnalysisProcessEnum
	measurements: WaterMonth_selectProjectByMonth_measurements[]
}

export interface WaterMonth {
	selectProjectByMonth: WaterMonth_selectProjectByMonth[]
}

export interface WaterMonthVariables {
	data: SelectManagementInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Projects
// ====================================================

export interface Projects_selectProjectListByRegDt_measurements_item {
	__typename: 'ItemEntity'
	id: number
	name: string
}

export interface Projects_selectProjectListByRegDt_measurements_sample {
	__typename: 'SampleEntity'
	id: number
	name: string
}

export interface Projects_selectProjectListByRegDt_measurements {
	__typename: 'MeasurementEntity'
	id: number
	ppm: number
	item: Projects_selectProjectListByRegDt_measurements_item
	sample: Projects_selectProjectListByRegDt_measurements_sample
	dilution: number
}

export interface Projects_selectProjectListByRegDt_user {
	__typename: 'MUserEntity'
	id: number
	name: string
}

export interface Projects_selectProjectListByRegDt_files_item {
	__typename: 'ItemEntity'
	id: number
	name: string
}

export interface Projects_selectProjectListByRegDt_files {
	__typename: 'ProjectFileEntity'
	id: number
	item: Projects_selectProjectListByRegDt_files_item
	filePath: string
}

export interface Projects_selectProjectListByRegDt {
	__typename: 'ProjectEntity'
	id: number
	regDt: any
	note: string | null
	measurements: Projects_selectProjectListByRegDt_measurements[]
	user: Projects_selectProjectListByRegDt_user
	files: Projects_selectProjectListByRegDt_files[]
}

export interface Projects {
	selectProjectListByRegDt: Projects_selectProjectListByRegDt[]
}

export interface ProjectsVariables {
	data: SelectProjectInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectItem
// ====================================================

export interface ProjectItem_selectItemList {
	__typename: 'ItemEntity'
	id: number
	name: string
	ord: number
	time: number
}

export interface ProjectItem_selectSampleList {
	__typename: 'SampleEntity'
	id: number
	name: string
	analysisProcess: AnalysisProcessEnum
	type: SampleTypeEnum
	ord: number
}

export interface ProjectItem {
	selectItemList: ProjectItem_selectItemList[]
	selectSampleList: ProjectItem_selectSampleList[]
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateProjectFile
// ====================================================

export interface UpdateProjectFile_updateProjectFile {
	__typename: 'OutputByBoolean'
	data: boolean | null
}

export interface UpdateProjectFile {
	updateProjectFile: UpdateProjectFile_updateProjectFile
}

export interface UpdateProjectFileVariables {
	data: UpdateProjectFileInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteProjectFile
// ====================================================

export interface DeleteProjectFile_deleteProjectFile {
	__typename: 'OutputByBoolean'
	data: boolean | null
}

export interface DeleteProjectFile {
	deleteProjectFile: DeleteProjectFile_deleteProjectFile
}

export interface DeleteProjectFileVariables {
	id: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * 공정 방법
 */
export enum AnalysisProcessEnum {
	A2O = 'A2O',
	MBR = 'MBR',
}

/**
 * 성공 여부
 */
export enum DailyCheckItemEnum {
	ERROR = 'ERROR',
	NONE = 'NONE',
	SUCCESS = 'SUCCESS',
}

/**
 * 거래처 타입
 */
export enum GroupTypeEnum {
	PURCHASE = 'PURCHASE',
	SALES = 'SALES',
}

/**
 * 활성화 여부
 */
export enum IsActivateEnum {
	ACTIVATE = 'ACTIVATE',
	UN_ACTIVATE = 'UN_ACTIVATE',
}

/**
 * 아이템 타입
 */
export enum ItemTypeEnum {
	ITEM1 = 'ITEM1',
	ITEM2 = 'ITEM2',
	ITEM3 = 'ITEM3',
}

/**
 * 셈플 인풋 타입
 */
export enum SampleTypeEnum {
	BUTTON = 'BUTTON',
	INPUT = 'INPUT',
	NULL = 'NULL',
}

/**
 * 검색종류
 */
export enum SearchKindEnum {
	ADDRESS = 'ADDRESS',
	AREA = 'AREA',
	DEVICE_ID = 'DEVICE_ID',
	GROUP_NAME = 'GROUP_NAME',
	ITEM_NAME = 'ITEM_NAME',
	MANAGER = 'MANAGER',
	MODEL_NAME = 'MODEL_NAME',
	PHONE_NUMBER = 'PHONE_NUMBER',
	REG_DT = 'REG_DT',
	REQ_DELIVERY_DATE = 'REQ_DELIVERY_DATE',
	SITE_NAME = 'SITE_NAME',
	USER_ID = 'USER_ID',
	EMAIL = 'EMAIL',
	USER_NAME = 'USER_NAME',
}

/**
 * 일반, 리스
 */
export enum SiteSalesTypeEnum {
	CHEMICAL = 'CHEMICAL',
	ITEM = 'ITEM',
}

export interface DailyCheckByDayInput {
	regDt?: string | null
	siteId?: number | null
}

export interface GetCountInput {
	year: string
	siteId: number
}

export interface InsertChemicalHistory {
	id?: number | null
	itemId: number
	siteId: number
	orderAmount: number
	useAmount: number
	remains?: number | null
}

export interface InsertChemicalHistoryInput {
	chemicals: InsertChemicalHistory[]
}

export interface InsertDailyCheckInput {
	id?: number | null
	siteId: number
	dailyCheckItem: InsertDailyCheckItemInput[]
}

export interface InsertDailyCheckItemInput {
	id?: number | null
	codeId: number
	value: DailyCheckItemEnum
	memo?: string | null
	dailyCheckId?: number | null
}

export interface InsertDiaryInput {
	id?: number | null
	siteId: number
	diaryItems: InsertDiaryItemInput[]
}

export interface InsertDiaryItemInput {
	id?: number | null
	codeId: number
	value: string
}

export interface InsertFacilityInput {
	id?: number | null
	siteId?: number | null
	locationCodeId: number
	titleCodeId: number
	amount: number
	dateOfReceipt: any
	completedDate: any
	detail?: string | null
	company: string
	cost: number
	memo?: string | null
}

export interface InsertGroupInput {
	groupName: string
	manager: string
	officePhone?: string | null
	cellPhone?: string | null
	address: string
	memo?: string | null
	contractFrom?: any | null
	contractTo?: any | null
	itemPrices: InsertItemPriceInput[]
	type: GroupTypeEnum
}

export interface InsertInfluentFlowInput {
	id?: number | null
	userId: number
	siteId: number
	influentFlowItems: InsertInfluentFlowItemInput[]
	inspector: string
	lat?: number | null
	lon?: number | null
}

export interface InsertInfluentFlowItemInput {
	flowId: number
	result: number
}

export interface InsertItemPriceInput {
	itemId: number
	price: number
}

export interface InsertNoticeInput {
	title: string
	content: string
}

export interface InsertSiteInput {
	id?: number | null
	name: string
	groupId: number
	zipcode?: string | null
	address?: string | null
	memo?: string | null
	headquarters?: string | null
	branch?: string | null
	contract?: number | null
	reagent?: string | null
	capacity?: number | null
	manager?: string | null
	cellPhone?: string | null
	chargingDevice?: number | null
	chargingReagent?: number | null
	deliveryFee?: number | null
	salesType?: SiteSalesTypeEnum | null
	areaId: number
}

export interface InsertSludgeInput {
	id?: number | null
	siteId: number
	value: number
	generatingFacility: string
	transferProcessingFacility: string
	transferCompany: string
}

export interface SelectChemicalHistoryListInput {
	ids?: number[] | null
	siteId?: number | null
	regDt?: string | null
}

export interface SelectDiaryInput {
	id?: number | null
	regDt?: string | null
	siteId?: number | null
}

export interface SelectFacilityInput {
	id: number
}

export interface SelectFacilityListInputByWeb {
	searchKind?: SearchKindEnum | null
	search?: string | null
	pageNo?: number | null
	lastPageNo?: number | null
	totalCount?: number | null
	limit?: number | null
	startNo?: number | null
	endNo?: number | null
	skip?: number | null
	type?: string | null
	startDt?: string | null
	endDt?: string | null
	regDt?: string | null
	siteId?: number | null
}

export interface SelectGroupsInput {
	searchKind?: SearchKindEnum | null
	search?: string | null
	pageNo?: number | null
	lastPageNo?: number | null
	totalCount?: number | null
	limit?: number | null
	startNo?: number | null
	endNo?: number | null
	skip?: number | null
	type?: string | null
	startDt?: string | null
	endDt?: string | null
}

export interface SelectInfluentFlowListInput {
	fromRegDt?: string | null
	toRegDt?: string | null
	siteId?: number | null
	flowIds?: number[] | null
}

export interface SelectItemListInput {
	type?: ItemTypeEnum[] | null
}

export interface SelectManagementInput {
	regDt?: string | null
	siteId?: number | null
}

export interface SelectNoticeListInput {
	searchKind?: SearchKindEnum | null
	search?: string | null
	pageNo?: number | null
	lastPageNo?: number | null
	totalCount?: number | null
	limit?: number | null
	startNo?: number | null
	endNo?: number | null
	skip?: number | null
	type?: string | null
	startDt?: string | null
	endDt?: string | null
}

export interface SelectProjectInput {
	id?: number | null
	regDt?: string | null
	siteId?: number | null
}

export interface SelectSiteInput {
	id: number
}

export interface SelectSitesInput {
	searchKind?: SearchKindEnum | null
	search?: string | null
	pageNo?: number | null
	lastPageNo?: number | null
	totalCount?: number | null
	limit?: number | null
	startNo?: number | null
	endNo?: number | null
	skip?: number | null
	type?: string | null
	startDt?: string | null
	endDt?: string | null
	ids?: number[] | null
	groupId?: number | null
}

export interface SelectSludgeListInput {
	regDt?: string | null
	siteId?: number | null
}

export interface SelectUserInput {
	id: number
}

export interface SelectUserListInput {
	searchKind?: SearchKindEnum | null
	search?: string | null
	pageNo?: number | null
	lastPageNo?: number | null
	totalCount?: number | null
	limit?: number | null
	startNo?: number | null
	endNo?: number | null
	skip?: number | null
	type?: string | null
	startDt?: string | null
	endDt?: string | null
}

export interface SignUpInput {
	userId: string
	password: string
	groupId?: number | null
	name?: string | null
	cellPhone?: string | null
	email?: string | null
	roleId?: number | null
	memo?: string | null
	siteIds?: number[] | null
}

export interface UpdateFacilityInput {
	id: number
	amount?: number | null
	dateOfReceipt?: any | null
	completedDate?: any | null
	detail?: string | null
	company?: string | null
	cost?: number | null
	memo?: string | null
	isActivate?: IsActivateEnum | null
	siteId?: number | null
	locationCodeId?: number | null
	titleCodeId?: number | null
}

export interface UpdateGroupInput {
	id: number
	groupName?: string | null
	manager?: string | null
	officePhone?: string | null
	cellPhone?: string | null
	address?: string | null
	memo?: string | null
	contractFrom?: any | null
	contractTo?: any | null
	itemPrices: UpdateItemPriceInput[]
	type?: string | null
}

export interface UpdateItemPriceInput {
	id: number
	itemId: number
	price: number
}

export interface UpdateNoticeInput {
	id: number
	title: string
	content: string
}

export interface UpdateProjectFileInput {
	projectId: number
	itemId: number
	filePath: string
}

export interface UpdateSiteInput {
	id?: number | null
	name?: string | null
	groupId?: number | null
	zipcode?: string | null
	address?: string | null
	memo?: string | null
	headquarters?: string | null
	branch?: string | null
	contract?: number | null
	reagent?: string | null
	capacity?: number | null
	manager?: string | null
	cellPhone?: string | null
	chargingDevice?: number | null
	chargingReagent?: number | null
	deliveryFee?: number | null
	salesType?: SiteSalesTypeEnum | null
	isActivate?: IsActivateEnum | null
	areaId?: number | null
}

export interface UpdateSludgeInput {
	id?: number | null
	value?: number | null
	generatingFacility?: string | null
	transferProcessingFacility?: string | null
	transferCompany?: string | null
	isActivate?: IsActivateEnum | null
}

export interface UpdateUserInput {
	id: number
	userId?: string | null
	password?: string | null
	groupId?: number | null
	name?: string | null
	cellPhone?: string | null
	email?: string | null
	roleId?: number | null
	isActivate?: IsActivateEnum | null
	memo?: string | null
	delDt?: any | null
	siteIds?: number[] | null
}

//==============================================================
// END Enums and Input Objects
//==============================================================
