import { css } from '@emotion/react'
import Selector from 'components/selector/Selector'
import Table from 'components/table'
import TitleTable from 'components/templete/titleTable'
import React, { useCallback } from 'react'
import { SelectorValueType } from 'types/common'
import { Groups_selectGroupList_groups, SignUpInput, UpdateUserInput } from 'types/graphql'

type Props = {
	data: SignUpInput | UpdateUserInput
	onChangeSelectorByValue: (type: string) => (value: SelectorValueType) => void
	groups?: Groups_selectGroupList_groups[]
	onChangeSelectorBySiteId(siteId: number): () => void
}
const UserSiteInputTable: React.FC<Props> = ({ data, groups, onChangeSelectorByValue, onChangeSelectorBySiteId }) => {
	const getSites = useCallback(() => {
		const group = groups?.filter(group => group.id === data.groupId)[0]
		return group ? group.sites.map(site => ({ label: site.name, value: site.id })) : []
	}, [data.groupId, groups])

	const getSite = useCallback((siteId: number) => {
		const group = groups?.filter(group => group.id === data.groupId)[0]
		return group?.sites.find(site => site.id === siteId)
	}, [data.groupId, groups])

	const getSiteIds = useCallback(() => {
		return data.siteIds ? data.siteIds?.map(siteId => String(siteId)) : []
	}, [data.siteIds])

	return (
		<TitleTable
			title="거래처 / 소속 입력"
			name="userInGroup"
			style={css`
				margin-top: 3rem;
			`}
		>
			<Table>
				<Table.Row>
					<Table.Col flex={5}>
						<Selector
							options={
								groups
									? [
											{ label: '그룹을 선택해주세요.', value: -1 },
											...groups.map(group => ({ label: group.groupName, value: group.id })),
									  ]
									: []
							}
							values={[data.groupId ? String(data.groupId) : '-1']}
							onChange={onChangeSelectorByValue('groupId')}
							css={{ width: '100%', height: '3.2rem' }}
						/>
					</Table.Col>
					<Table.Col flex={5}>
						<Selector
							options={getSites()}
							values={getSiteIds()}
							css={{ width: '100%', height: '3.2rem' }}
							onToggle={onChangeSelectorBySiteId}
						/>
					</Table.Col>
				</Table.Row>
			</Table>
			<Table>
				<Table.Row>
					<Table.Col flex={5}>
						<p>No.</p>
					</Table.Col>
					<Table.Col flex={5}>
						<p>휴게소</p>
					</Table.Col>
					<Table.Col flex={5}>
						<p>주소</p>
					</Table.Col>
				</Table.Row>
				{
					data.siteIds?.map((siteId, index) => {
						const site = getSite(Number(siteId))
						if(!site) return null
						return(
						<Table.Row key={`${index}-${siteId}`}>
							<Table.Col flex={5}>
								<p>{index + 1}</p>
							</Table.Col>	
							<Table.Col flex={5}>
								<p>{site?.name}</p>
							</Table.Col>
							<Table.Col flex={5}>
								<p>{site?.address}</p>
							</Table.Col>
						</Table.Row>
					)})
				}
			</Table>
		</TitleTable>
	)
}

export default UserSiteInputTable
