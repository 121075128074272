import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import SiteStore from 'stores/SiteStore'
import { SelectorValueType } from 'types/common'
import { SearchKindEnum } from 'types/graphql'
import { getURLParameters } from 'utils/common'

function useSearch(baseUrl: string, initData?: SearchType) {
	const onChangeType = getSearchInitData(SearchKindEnum.MANAGER)
	const { search } = useLocation()
	const { push } = useHistory()
	const [searchData, setSearchData] = useState<SearchType>(
		initData
			? initData
			: {
					type: '',
					searchKind: SearchKindEnum.AREA,
					search: '',
					year: moment().format('YYYY'),
					month: moment().format('MM'),
					pageNo: '1',
					siteId: SiteStore.sites ? SiteStore.sites[0].id : -1,
					regDt: moment().format('YYYY-MM-DD'),
			  },
	)

	useEffect(() => {
		const result = getURLParameters<SearchType>(search)
		setSearchData({
			type: result?.type || '',
			pageNo: result?.pageNo || '1',
			searchKind: result?.searchKind || initData?.searchKind || '',
			search: result?.search || '',
			month: result?.month || moment().format('MM'),
			year: result?.year || moment().format('YYYY'),
			siteId: result?.siteId || (SiteStore.sites && SiteStore.sites[0] ? SiteStore.sites[0].id : -1),
			regDt: result?.regDt || moment().format('YYYY-MM-DD'),
		})
	}, [search])

	const onChange = (type: keyof typeof onChangeType, value: string) => {
		setSearchData({ ...searchData, [type]: value })
	}

	const onPush = useCallback(() => {
		push(
			`${baseUrl}?year=${searchData.year}&month=${searchData.month}&type=${searchData.type}&search=${searchData.search}&searchKind=${searchData.searchKind}&pageNo=1&siteId=${searchData.siteId}&regDt=${searchData.regDt}`,
		)
	}, [baseUrl, searchData])

	const onPushByUrl = useCallback((url: string) => {
		push(
			`${url}?year=${searchData.year}&month=${searchData.month}&type=${searchData.type}&search=${searchData.search}&searchKind=${searchData.searchKind}&pageNo=${searchData.pageNo}&siteId=${searchData.siteId}&regDt=${searchData.regDt}`,
		)
	}, [baseUrl, searchData])

	const onPushByType = useCallback((type: string) => {
		push(
			`${baseUrl}?year=${searchData.year}&month=${searchData.month}&type=${type}&search=${searchData.search}&searchKind=${searchData.searchKind}&pageNo=${searchData.pageNo}&siteId=${searchData.siteId}&regDt=${searchData.regDt}`,
		)
	}, [baseUrl, searchData])

	const onPushByYear = useCallback((year: string) => {
		push(
			`${baseUrl}?year=${year}&month=${searchData.month}&type=${searchData.type}&search=${searchData.search}&searchKind=${searchData.searchKind}&pageNo=${searchData.pageNo}&siteId=${searchData.siteId}&regDt=${searchData.regDt}`,
		)
	}, [baseUrl, searchData])

	const getUrlByPageNo = useCallback((pageNo: number) => {
		return `${baseUrl}?year=${searchData.year}&month=${searchData.month}&type=${searchData.type}&search=${searchData.search}&searchKind=${searchData.searchKind}&pageNo=${pageNo}&siteId=${searchData.siteId}&regDt=${searchData.regDt}`
	}, [baseUrl, searchData])

	const onPushBySiteId = useCallback((siteId: number) => {
		push(
			`${baseUrl}?year=${searchData.year}&month=${searchData.month}&type=${searchData.type}&search=${searchData.search}&searchKind=${searchData.searchKind}&pageNo=${searchData.pageNo}&siteId=${siteId}&regDt=${searchData.regDt}`,
		)
	}, [baseUrl, searchData])

	const onChangeSelector = (value: SelectorValueType) => {
		onChange('searchKind', String(value))
	}

	const onChangeSiteId = (value: SelectorValueType) => {
		onChange('siteId', value)
	}

	const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange('search', e.target.value)
	}

	const onChangeRegDt = (date: Date) => {
		onChange('regDt', moment(date).format('YYYY-MM-DD'))
	}

	const getNextMonth = () => {
		const nextMonth = Number(searchData.month) + 1
		if (nextMonth === 13 || moment().format('YYYY-MM') === `${searchData.year}-${searchData.month}`)
			return searchData.month
		return String(nextMonth).length === 1 ? `0${nextMonth}` : String(nextMonth)
	}

	const getPrevMonth = () => {
		const prevMonth = Number(searchData.month) - 1
		if (searchData.month === '01') return searchData.month
		return String(prevMonth).length === 1 ? `0${prevMonth}` : String(prevMonth)
	}

	const getMonthPush = (month?: string) => {
		push(
			`${baseUrl}?year=${searchData.year}&month=${month}&type=${searchData.type}&search=${searchData.search}&searchKind=${searchData.searchKind}&pageNo=${searchData.pageNo}&siteId=${searchData.siteId}`,
		)
	}
	const onNext = () => {
		const month = getNextMonth()
		setSearchData({ ...searchData, month: month || moment().format('MM') })
		getMonthPush(month)
	}

	const onPrev = () => {
		const month = getPrevMonth()
		setSearchData({ ...searchData, month: month || moment().format('MM') })
		getMonthPush(month)
	}

	return {
		searchData,
		onChange,
		onPush,
		getUrlByPageNo,
		onPushByType,
		onChangeSelector,
		onChangeSearch,
		onNext,
		onPrev,
		getNextMonth,
		getPrevMonth,
		getMonthPush,
		onPushByYear,
		onPushByUrl,
		onChangeSiteId,
		onPushBySiteId,
		onChangeRegDt,
	}
}

export const getSearchInitData = (searchKind: SearchKindEnum) => {
	return {
		type: '',
		searchKind: searchKind,
		search: '',
		year: moment().format('YYYY'),
		month: moment().format('MM'),
		pageNo: '1',
		siteId: -1,
		regDt: moment().format('YYYY-MM-DD'),
	}
}

export const getSearchInitDataBySiteId = (siteId: number) => {
	return {
		type: '',
		searchKind: SearchKindEnum.GROUP_NAME,
		search: '',
		year: moment().format('YYYY'),
		month: moment().format('MM'),
		pageNo: '1',
		siteId: siteId,
		regDt: moment().format('YYYY-MM-DD'),
	}
}

export type SearchType = {
	type: string
	searchKind: SearchKindEnum | ''
	search: string
	year: string
	month: string
	pageNo: string
	siteId: number
	regDt: string
}

export const onChangeType = getSearchInitData(SearchKindEnum.MANAGER)

export default useSearch
