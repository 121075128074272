import { gql } from '@apollo/client'

export const CREATE_GROUP_QUERY = gql`
	mutation CreateGroup($data: InsertGroupInput!) {
		insertGroup(data: $data) {
			id
		}
	}
`

export const GROUPS_QUERY = gql`
	query Groups($data: SelectGroupsInput!) {
		selectGroupList(data: $data) {
			groups {
				id
				groupName
				manager
				cellPhone
				officePhone
				memo
				address
				regDt
				type

				sites {
					id
					name
				}
			}
		}
	}
`

export const GROUPS_AREA_QUERY = gql`
	query GroupsArea($data: SelectGroupsInput!, $ids: [Float!]!) {
		selectGroupList(data: $data) {
			groups {
				id
				groupName
				manager
				cellPhone
				officePhone
				memo
				address
				regDt
				type

				sites {
					id
					name
					address
				}
			}
		}
		selectCodeList(data: { ids: $ids }) {
			data {
				id
				name
				childCodes {
					id
					name
				}
			}
		}
	}
`

export const GROUPS_ITEM_QUERY = gql`
	query GroupsItem($data: SelectGroupsInput!, $type: [ItemTypeEnum!]) {
		selectGroupList(data: $data) {
			groups {
				id
				groupName
				manager
				cellPhone
				officePhone
				memo
				address
				regDt
				type

				sites {
					id
					name
				}
			}
		}

		selectItemList(data: { type: $type }) {
			id
			name
		}
	}
`

export const GROUPS_PAGING_QUERY = gql`
	query GroupsPaging($data: SelectGroupsInput!) {
		selectGroupList(data: $data) {
			groups {
				id
				groupName
				manager
				cellPhone
				officePhone
				memo
				address
				regDt
				type

				sites {
					id
					name
				}
			}
			paging {
				startNo
				endNo
				lastPageNo
				pageNo
			}
		}
	}
`

export const GROUP_QUERY = gql`
	query Group($groupId: Float!, $itemTypes: [ItemTypeEnum!]) {
		selectGroup(data: { id: $groupId }) {
			id
			groupName
			manager
			cellPhone
			officePhone
			memo
			address
			regDt
			contractFrom
			contractTo
			type
			itemPrices {
				id
				item {
					id
					name
				}
				price
			}
		}
		selectItemList(data: { type: $itemTypes }) {
			id
			name
			measure
			type
		}
	}
`

export const REMOVE_GROUP_QUERY = gql`
	mutation RemoveGroup($id: Float!) {
		deleteGroup(id: $id)
	}
`
export const UPDATE_GROUP_QUERY = gql`
	mutation UpdateGroup($data: UpdateGroupInput!) {
		updateGroup(data: $data) {
			data
		}
	}
`
