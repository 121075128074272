import React from 'react'
import Table from 'components/table'
import palette from 'styles/palette'
import { Input } from 'components/input'
import Selector from 'components/selector/Selector'
import TitleTable from 'components/templete/titleTable'
import { GroupsArea_selectCodeList_data, SignUpInput, UpdateUserInput } from 'types/graphql'
import { SelectorValueType } from 'types/common'

type Props = {
	data: SignUpInput | UpdateUserInput
	onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void
	onChangeSelectorByValue: (type: string) => (value: SelectorValueType) => void
	roles?: GroupsArea_selectCodeList_data[] | null
}
const UserInputTable: React.FC<Props> = ({ data, roles, onChangeSelectorByValue, onChangeInput }) => {
	return (
		<TitleTable title="계정 정보 입력" name="accountMng">
			<Table>
				<Table.Row>
					<Table.Col bgColor={palette.tableHeadColor} flex={1}>
						아이디
					</Table.Col>
					<Table.Col flex={4}>
						<Input css={{ width: '100%' }} value={data.userId || ''} onChange={onChangeInput} name="userId" />
					</Table.Col>
					<Table.Col bgColor={palette.tableHeadColor} flex={1}>
						패스워드
					</Table.Col>
					<Table.Col flex={4}>
						<Input css={{ width: '100%' }} value={data.password || ''} onChange={onChangeInput} name="password" />
					</Table.Col>
				</Table.Row>
				<Table.Row>
					<Table.Col bgColor={palette.tableHeadColor} flex={1}>
						이름
					</Table.Col>
					<Table.Col flex={4}>
						<Input css={{ width: '100%' }} value={data.name || ''} onChange={onChangeInput} name="name" />
					</Table.Col>
					<Table.Col bgColor={palette.tableHeadColor} flex={1}>
						이메일
					</Table.Col>
					<Table.Col flex={4}>
						<Input css={{ width: '100%' }} value={data.email || ''} onChange={onChangeInput} name="email" />
					</Table.Col>
				</Table.Row>
				<Table.Row>
					<Table.Col bgColor={palette.tableHeadColor} flex={1}>
						휴대폰
					</Table.Col>
					<Table.Col flex={4}>
						<Input css={{ width: '100%' }} value={data.cellPhone || ''} onChange={onChangeInput} name="cellPhone" />
					</Table.Col>
					<Table.Col bgColor={palette.tableHeadColor} flex={1}>
						권한
					</Table.Col>
					<Table.Col flex={4}>
						<Selector
							options={
								roles
									? [
											{ label: '권한을 선택해주세요.', value: '0' },
											...roles[0].childCodes.map(role => {
												return { label: role.name, value: role.id }
											}),
									  ]
									: []
							}
							values={[String(data.roleId)]}
							onChange={onChangeSelectorByValue('roleId')}
							css={{ width: '100%', height: '3.2rem', zIndex: 5 }}
						/>
					</Table.Col>
				</Table.Row>
				{/*<Table.Row>*/}
				{/*	<Table.Col bgColor={palette.tableHeadColor} flex={0.96}>*/}
				{/*		메모*/}
				{/*	</Table.Col>*/}
				{/*	<Table.Col flex={9.04}>*/}
				{/*		<Input css={{ width: '100%' }} value={data.memo || ''} onChange={onChangeInput('memo')} />*/}
				{/*	</Table.Col>*/}
				{/*</Table.Row>*/}
			</Table>
		</TitleTable>
	)
}

export default UserInputTable
